import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import Geocoder from '@pderas/vue2-geocoder'
import * as VueGoogleMaps from 'vue2-google-maps'

import VueProgressBar from 'vue-progressbar'

import i18n from '@/libs/i18n'

import router from './router'

import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import '@/@fake-db/db'

Vue.prototype.$isProduction = process.env.NODE_ENV === 'production'
Vue.prototype.$isDevelopment = process.env.NODE_ENV === 'development'
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: 'places',
  },
})

Vue.use(Geocoder, {
  // defaultCountryCode: 'GR',
  defaultLanguage: 'el', // e.g. 'en'
  defaultMode: 'address', // or 'lat-lng'
  googleMapsApiKey: process.env.VUE_APP_GOOGLE_GEOCODER_API_KEY,
})

Vue.use(VueProgressBar, {
  color: '#A7844E',
  failedColor: '#874b4b',
  thickness: '5px',
  autoRevert: true,
  autoFinish: false,
  location: 'top',
  inverse: false,
})

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  i18n,
  created() {
    const getHTMLTag = document.documentElement
    getHTMLTag.setAttribute('lang', i18n.locale)
  },
  render: h => h(App),
}).$mount('#app')
