import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from '@/store/app'
import appConfig from '@/store/app-config'
import verticalMenu from '@/store/vertical-menu'
import commonStoreModule from '@/store/commonStoreModule'
import chatStoreModule from '@/store/chatStoreModule'
import productTable from '@/store/productTable'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    productTable,
    'app-common': commonStoreModule,
    'app-chat': chatStoreModule,
  },
  strict: process.env.DEV,
})
