export default {
    state: {
        productcode: null
    },
    mutations: {
        setProductCode(state, data) {
            state.productcode = data
        }
    },
    getters: {
        getProductCode(state) {
            return state.productcode
        }
    }
}