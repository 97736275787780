import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return (
    localStorage.getItem('userData') &&
    localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
  )
}

export const getUserId = () => JSON.parse(localStorage.getItem('userData')).id

export const getCompanyId = () =>
  JSON.parse(localStorage.getItem('userData')).company.id

export const getSubscription = () =>
  JSON.parse(localStorage.getItem('userData')).userGroup

export const getSubscriptionTypes = () => [
  { id: 'MONTHLY', title: 'MONTHLY' },
  { id: 'YEARLY', title: 'YEARLY' },
]

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getUserRights = () => {
  const user = getUserData()
  const rights = []
  if (user && user.activeUserGroup) {
    user.activeUserGroup.userGroupRights.forEach((right) => {
      rights.push(right.authority)
    })
  }else {
    if (user && user.userGroup) {
      user.userGroup.userGroupRights.forEach((right) => {
        rights.push(right.authority)
      })
    }
  }

  return rights
}

export const isRoleType = (roleType) => {
  const user = getUserData()
  return (
    user &&
    user.roles &&
    user.roles.filter((userRole) => userRole.title === roleType).length > 0
  )
}

export const isAdmin = () => isRoleType('admin')

export const isSeller = () => isRoleType('seller')

export const isSupplier = () => isRoleType('supplier')

export const isDesigner = () => isRoleType('designer')

export const canEditPrice = () =>
  isAdmin() ||
  (isSeller() &&
    getUserRights().includes('Products_Product_Edit') &&
    getUserRights().includes('Products_Product_Price_Edit'))

export const canPublishToShop = () =>
  isAdmin() ||
  (isSeller() && getUserRights().includes('Products_Product_Publish_Edit'))

export const canUnPublishFromShop = (productSupplier) =>
  isAdmin() ||
  (getUserRights().includes('Products_Product_Status_Edit') &&
    (isSupplier() || (isSeller() && !productSupplier)))

export const canEditSubscription = () =>
  getUserRights().includes('Users_Subscriptions_Edit')

export const getHomeRouteForLoggedInUser = () => '/'
