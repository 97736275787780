export default [
  {
    path: '/products/list',
    name: 'products-list',
    component: () => import('@/views/products/products-list/ProductsList.vue'),
    meta: {
      authority: 'Products_Product_View',
    },
  },
  {
    path:'/product-log',
    name:'products-log',
    component:()=> import('@/views/products-log/ProductLogList.vue'),
    meta:{
      authority:'General_MainParameter_View',
      pageTitle:'Product Log ',
      breadcrumb: [
        {
          text: 'Product Log ',
          active: true,
        },
      ],
    }
  },
  {
    path: '/browse-products',
    name: 'all-products-list',
    component: () => import('@/views/products/products-list/AllSupplierProductsList.vue'),
    meta: {
      authority: 'Users_Partner_Connect_Edit',
    },
  },
  {
    path: '/supplier-products/list',
    name: 'supplier-products-list',
    component: () => import('@/views/products/products-list/SupplierProductsList.vue'),
    meta: {
      authority: 'Users_Partner_Connect_Edit',
    },
  },
  {
    path: '/seller-products/list',
    name: 'seller-products-list',
    component: () => import('@/views/products/products-list/SellerProductsList.vue'),
    meta: {
      authority: 'Users_Partner_Connect_Edit',
    },
  },
  {
    path: '/active-partners/list',
    name: 'active-partners-list',
    component: () => import('@/views/products/products-list/SellerProductsList.vue'),
    meta: {
      authority: 'Users_Partner_Connect_Edit',
    },
  },
  {
    path: '/sell-requests/list',
    name: 'sell-requests-list',
    component: () => import('@/views/products/products-list/SellRequestsList.vue'),
    meta: {
      authority: 'Products_Sell_Request_Edit',
    },
  },
  {
    path: '/products/edit/:id?',
    name: 'products-edit',
    component: () => import('@/views/products/products-edit/ProductsEdit.vue'),
    meta: {
      authority: 'Products_Product_Edit',
    },
  },
  {
    path: '/deals',
    name: 'deals-list',
    component: () => import('@/views/deals/deals-list/DealsList.vue'),
    meta: {
      // authority: 'Products_Deals_View',
      authority: 'Products_Product_View',
      
    },
  },
  {
    path: '/deals/edit/:id?',
    name: 'deals-edit',
    component: () => import('@/views/deals/deals-edit/DealsEdit.vue'),
    meta: {
      authority: 'Products_Product_Edit',
    },
  },
]
