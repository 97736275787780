export default [
  {
    path: '/emails/welcome',
    name: 'email-welcome',
    component: () => import('@/views/email-templates/welcome/Welcome.vue'),
  },
  {
    path: '/emails/activation',
    name: 'email-activation',
    component: () => import('@/views/email-templates/activation/Activation.vue'),
  },
  {
    path: '/emails/reset-password',
    name: 'email-reset-password',
    component: () => import('@/views/email-templates/reset-password/ResetPassword.vue'),
  },
  {
    path: '/emails/subscription-end',
    name: 'subscription-end',
    component: () => import('@/views/email-templates/subscription-end/SubscriptionEnd.vue'),
  },
  {
    path: '/emails/payment-success',
    name: 'payment-success',
    component: () => import('@/views/email-templates/payment-success/PaymentSuccess.vue'),
  },
  {
    path: '/emails/product-approved',
    name: 'product-approved',
    component: () => import('@/views/email-templates/product-approved/ProductApproved.vue'),
  },
  {
    path: '/emails/monthly-report',
    name: 'monthly-report',
    component: () => import('@/views/email-templates/monthly-report/MonthlyReport.vue'),
  },
]
