export default [
  {
    path: '/orders/list',
    name: 'orders-list',
    component: () => import('@/views/orders/orders-list/OrdersList.vue'),
    meta: {
      authority: 'Orders_Order_View',
    },
  },
  {
    path: '/orders/edit/:id?',
    name: 'orders-edit',
    component: () => import('@/views/orders/orders-edit/OrdersEdit.vue'),
    meta: {
      authority: 'Orders_Order_Edit',
    },
  },
]
