import Vue from 'vue'
import VueRouter from 'vue-router'

import { getUserRights, isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'

import products from './products'
import orders from './orders'
import emails from './emails'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...products,
    ...orders,
    ...emails,
    {
      path: '/',
      name: 'dashboard',
      alias: '/dashboard',
      component: () => import('@/views/dashboard/Ecommerce.vue'),
    },
    {
      path: '/social-trade',
      name: 'social-trade',
      component: () => import('@/views/chat/Chat.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'chat-application',
      },
    },
    {
      path: '/account-settings',
      name: 'account-settings',
      component: () => import('@/views/account-settings/AccountSettings.vue'),
      meta: {
        pageTitle: 'Account Settings',
        breadcrumb: [
          {
            text: 'Account Settings',
            active: true,
          },
        ],
      },
    },
    {
      path: '/change-log',
      name: 'changelog',
      component: () => import('@/views/changelog/Changelog.vue'),
      meta: {
        pageTitle: 'App Version',
        breadcrumb: [
          {
            text: 'Changelog',
            active: true,
          },
        ],
      },
    },

    {
      path: '/announcements/:categoryID?',
      name: 'announcements',
      component: () => import('@/views/announcements/announcements-list/AnnouncementsList.vue'),
    },

    {
      path: '/my-announcements',
      name: 'my-announcements',
      component: () => import('@/views/announcements/announcements-list/AnnouncementsList.vue'),
    },

    {
      path: '/notifications',
      name: 'notifications',
      component: () => import('@/views/notifications/notifications-list/NotificationsList.vue'),
      meta: {
        pageTitle: 'Notifications',
        breadcrumb: [
          {
            text: 'Notifications',
            active: true,
          },
        ],
      },
    },

    {
      path: '/announcement/:id',
      name: 'announcement-single',
      component: () => import('@/views/announcements/announcements-single/AnnouncementsSingle.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Blog',
            to: '/announcements',
          },
        ],
      },
    },

    {
      path: '/promotion/:id',
      name: 'promotion-single',
      component: () => import('@/views/promotions/promotions-single/PromotionsSingle.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Blog',
            to: '/announcements',
          },
        ],
      },
    },

    // -------------------- ORDERS -------------------------
    // {
    //   path: '/order/list',
    //   name: 'order-list',
    //   component: () => import('@/views/order/order-list/OrderList.vue'),
    //   meta: {
    //     pageTitle: 'Orders',
    //     contentClass: 'ecommerce-application',
    //     breadcrumb: [
    //       {
    //         text: 'List',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/order/grid',
    //   name: 'order-grid',
    //   component: () => import('@/views/order/order-grid/OrderGrid.vue'),
    //   meta: {
    //     pageTitle: 'Orders',
    //     contentClass: 'ecommerce-application',
    //     breadcrumb: [
    //       {
    //         text: 'Grid',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/order/edit/:id',
    //   name: 'order-edit',
    //   component: () => import('@/views/order/order-edit/OrderEdit.vue'),
    //   meta: {
    //     pageTitle: 'Orders',
    //     contentClass: 'ecommerce-application',
    //     breadcrumb: [
    //       {
    //         text: 'Edit',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/product-categories',
      name: 'product-categories-list',
      component: () =>
        import('@/views/product-categories/product-categories-list/ProductCategoriesList.vue'),
      meta: {
        authority: 'Products_Product_Category_View',
      },
    },
    {
      path: '/product-categories/edit/:id?',
      name: 'product-categories-edit',
      component: () =>
        import('@/views/product-categories/product-categories-edit/ProductCategoriesEdit.vue'),
      meta: {
        authority: 'Products_Product_Category_View',
      },
    },
    {
      path: '/companies',
      name: 'companies-list',
      component: () => import('@/views/companies/companies-list/CompanyList.vue'),
      meta: {
        authority: 'Users_Company_View',
      },
    },
    {
      path: '/service-coverage',
      name: 'service-coverage',
      component: () => import('@/views/companies/companies-list/ServiceCoverage.vue'),
      meta: {
        authority: 'General_Cover_Area_View',
      },
    },
    {
      path: '/companies/edit/:id?',
      name: 'companies-edit',
      component: () => import('@/views/companies/companies-edit/CompanyEdit.vue'),
      meta: {
        authority: 'Users_Company_Edit',
      },
    },
    {
      path: '/stores',
      name: 'stores-list',
      component: () => import('@/views/stores/stores-list/StoresList.vue'),
      meta: {
        authority: 'Users_Company_View',
      },
    },
    {
      path: '/stores/edit/:id?',
      name: 'stores-edit',
      component: () => import('@/views/stores/stores-edit/StoresEdit.vue'),
      meta: {
        authority: 'Users_Company_Edit',
      },
    },
    {
      path: '/customers',
      name: 'customers-list',
      component: () => import('@/views/customers/customers-list/CustomersList.vue'),
      meta: {
        authority: 'Users_Customer_View',
      },
    },
    {
      path: '/customers/edit/:id?',
      name: 'customers-edit',
      component: () => import('@/views/customers/customers-edit/CustomersEdit.vue'),
      meta: {
        authority: 'Users_Customer_Edit',
      },
    },
    {
      path: '/lead-categories',
      name: 'leads-categories-list',
      component: () => import('@/views/leads/leads-list/LeadCategoriesList.vue'),
      meta: {
        authority: 'General_Lead_View',
      },
    },
    {
      path: '/leads',
      name: 'leads-list',
      component: () => import('@/views/leads/leads-list/LeadsList.vue'),
      meta: {
        authority: 'General_Lead_View',
      },
    },
    {
      path: '/leads/edit/:id?',
      name: 'leads-edit',
      component: () => import('@/views/leads/leads-edit/LeadsEdit.vue'),
      meta: {
        authority: 'General_Lead_Edit',
      },
    },
    {
      path: '/invoice/list',
      name: 'invoice-list',
      component: () => import('@/views/invoice/invoice-list/InvoiceList.vue'),
    },
    {
      path: '/invoice/preview/:id',
      name: 'invoice-preview',
      component: () => import('@/views/invoice/invoice-preview/InvoicePreview.vue'),
    },
    {
      path: '/new-payment/:id/:type',
      name: 'payment',
      component: () => import('@/views/invoice/payment/CardlinkRedirect.vue'),
    },
    {
      path: '/my-partner-requests',
      name: 'my-partner-requests-list',
      component: () => import('@/views/partner-requests/my-partner-requests/MyPartnerRequests.vue'),
    },
    {
      path: '/partner-requests/list',
      name: 'partner-requests-list',
      component: () =>
        import('@/views/partner-requests/partner-requests-list/PartnerRequestsList.vue'),
    },
    {
      path: '/partner-requests/edit/:id?',
      name: 'partner-requests-edit',
      component: () =>
        import('@/views/partner-requests/partner-requests-edit/PartnerRequestsEdit.vue'),
      meta: {
        authority: 'Users_Partner_Request_Edit',
      },
    },
    // {
    //   path: '/invoice/add/',
    //   name: 'invoice-add',
    //   component: () => import('@/views/invoice/invoice-add/InvoiceAdd.vue'),
    // },
    // {
    //   path: '/invoice/edit/:id',
    //   name: 'invoice-edit',
    //   component: () => import('@/views/invoice/invoice-edit/InvoiceEdit.vue'),
    // },
    {
      path: '/subscriptions',
      name: 'subscriptions',
      component: () => import('@/views/subscriptions/Subscriptions.vue'),
    },
    {
      path: '/supplier-selection',
      name: 'supplier-selection',
      component: () => import('@/views/companies/companies-list/SuppliersList.vue'),
      meta: {
        authority: 'Users_Partner_Browse_View',
      },
    },
    {
      path: '/seller-selection',
      name: 'seller-selection',
      component: () => import('@/views/companies/companies-list/SellersList.vue'),
      meta: {
        authority: 'Users_Partner_Browse_View',
      },
    },
    {
      path: '/insert-methods',
      name: 'insert-methods',
      component: () => import('@/views/products/products-list/InsertProductMethod.vue'),
      meta: {
        authority: 'Users_Partner_Browse_View',
      },
    },
    {
      path: '/announcements-admin',
      name: 'announcements-list',
      component: () =>
        import('@/views/announcements-admin/announcements-list/AnnouncementsList.vue'),
      meta: {
        authority: 'General_Announcement_View',
      },
    },
    {
      path: '/announcements/edit/:id?',
      name: 'announcements-edit',
      component: () =>
        import('@/views/announcements-admin/announcements-edit/AnnouncementsEdit.vue'),
      meta: {
        authority: 'General_Announcement_Edit',
      },
    },
    {
      path: '/post-categories',
      name: 'post-categories-list',
      component: () =>
        import('@/views/post-categories/post-categories-list/PostCategoriesList.vue'),
      meta: {
        authority: 'General_Post_Category_View',
      },
    },
    {
      path: '/product-tags',
      name: 'product-tags-list',
      component: () =>
        import('@/views/product-tags/product-tags-list/ProductTagsList.vue'),
      meta: {
        authority: 'General_MainParameter_View',
      },
    },
    {
      path: '/cover-areas',
      name: 'cover-areas-list',
      component: () => import('@/views/cover-areas/list/List.vue'),
      meta: {
        authority: 'General_Cover_Area_View',
      },
    },
    {
      path: '/municipalities',
      name: 'municipalities-list',
      component: () => import('@/views/dhmoi/list/List.vue'),
      meta: {
        authority: 'General_Cover_Area_View',
      },
    },
    {
      path: '/badges',
      name: 'badges-list',
      component: () => import('@/views/badges/list/List.vue'),
      meta: {
        authority: 'Users_Badges_View',
      },
    },
    {
      path: '/promotions',
      name: 'promotions-list',
      component: () => import('@/views/promotions/promotions-list/PromotionsList.vue'),
      meta: {
        authority: 'General_Promotion_View',
      },
    },
    {
      path: '/promotions/edit/:id?',
      name: 'promotions-edit',
      component: () => import('@/views/promotions/promotions-edit/PromotionsEdit.vue'),
      meta: {
        authority: 'General_Promotion_Edit',
      },
    },
    {
      path: '/users',
      name: 'users-list',
      component: () => import('@/views/users/users-list/UsersList.vue'),
      meta: {
        authority: 'Users_User_View',
      },
    },
    {
      path: '/users/view/:id',
      name: 'users-view',
      component: () => import('@/views/users/users-view/UsersView.vue'),
      meta: {
        authority: 'Users_User_View',
      },
    },
    {
      path: '/users/edit/:id?',
      name: 'users-edit',
      component: () => import('@/views/users/users-edit/UsersEdit.vue'),
      meta: {
        authority: 'Users_User_Edit',
      },
    },
    {
      path: '/user-groups',
      name: 'user-groups-list',
      component: () => import('@/views/user-groups/user-groups-list/UserGroupsList.vue'),
      meta: {
        authority: 'Users_User_Group_View',
      },
    },
    {
      path: '/user-groups/edit/:id?',
      name: 'user-groups-edit',
      component: () => import('@/views/user-groups/user-groups-edit/UserGroupsEdit.vue'),
      meta: {
        authority: 'Users_User_Group_Edit',
      },
    },
    {
      path: '/deal-subscriptions',
      name: 'deal-subscriptions-list',
      component: () => import('@/views/deal-subscriptions/deal-subscriptions-list/DealSubscriptionsList.vue'),
      meta: {
        authority: 'Users_User_Group_View',
      },
    },
    {
      path: '/deal-subscriptions/edit/:id?',
      name: 'deal-subscriptions-edit',
      component: () => import('@/views/deal-subscriptions/deal-subscriptions-edit/DealSubscriptionsEdit.vue'),
      meta: {
        authority: 'Users_User_Group_Edit',
      },
    },
    {
      path: '/normal-subscription-payments',
      name: 'normal-subscription-payments-list',
      component: () => import('@/views/normal-subscription-payments/normal-subscription-payments-list/NormalSubscriptionPaymentsList.vue'),
      meta: {
        authority: 'Users_Payment_View',
      },
    },
    {
      path: '/deal-subscription-payments',
      name: 'deal-subscription-payments-list',
      component: () => import('@/views/deal-subscription-payments/deal-subscription-payments-list/DealSubscriptionPaymentsList.vue'),
      meta: {
        authority: 'Users_Payment_View',
      },
    },
    {
      path: '/normal-subscription-payments/edit/:id?',
      name: 'normal-subscription-payments-edit',
      component: () => import('@/views/normal-subscription-payments/normal-subscription-payments-edit/NormalSubscriptionPaymentsEdit.vue'),
      meta: {
        authority: 'Users_User_Group_Edit',
      },
    },
    {
      path: '/deal-subscription-payments/edit/:id?',
      name: 'deal-subscription-payments-edit',
      component: () => import('@/views/deal-subscription-payments/deal-subscription-payments-edit/DealSubscriptionPaymentsEdit.vue'),
      meta: {
        authority: 'Users_User_Group_Edit',
      },
    },
    {
      path: '/normal-subscription-payments/view/:id?',
      name: 'normal-subscription-payments-view',
      component: () => import('@/views/normal-subscription-payments/normal-subscription-payments-view/NormalSubscriptionPaymentsView.vue'),
      meta: {
        authority: '',
      },
    },
    {
      path: '/deal-subscription-payments/view/:id?',
      name: 'deal-subscription-payments-view',
      component: () => import('@/views/deal-subscription-payments/deal-subscription-payments-view/DealSubscriptionPaymentsView.vue'),
      meta: {
        authority: '',
      },
    },

    {
      path: '/user-requests',
      name: 'user-requests-list',
      component: () => import('@/views/user-requests/user-requests-list/UserRequestsList.vue'),
      meta: {
        authority: 'Users_User_Request_View',
      },
    },
    {
      path: '/user-requests/edit/:id?',
      name: 'user-requests-edit',
      component: () => import('@/views/user-requests/user-requests-edit/UserRequestsEdit.vue'),
      meta: {
        authority: 'Users_User_Request_Edit',
      },
    },
    // {
    //   path: '/send-newsletter',
    //   name: 'send-newsletter',
    //   component: () => import('@/views/newsletter-wizard/NewsletterWizard.vue'),
    //   meta: {
    //     // @TODO newsletter permissions
    //     authority: 'Users_User_Request_Edit',
    //   },
    // },
    {
      path: '/main-parameters',
      name: 'main-parameters-list',
      component: () => import('@/views/main-parameters/List.vue'),
      meta: {
        authority: 'General_MainParameter_View',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/authentication/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/authentication/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/authentication/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/reset-password/:key',
      name: 'reset-password',
      component: () => import('@/views/authentication/ResetPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/authentication/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/vimeo',
      name: 'vimeo',
      component: () => import('@/components/VimeoUpload.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/active-suppliers',
      name: 'active-suppliers',
      component: () => import('@/views/active-suppliers/ActiveSuppliers.vue'),
      meta: {
        authority: 'Users_Partner_Browse_View',
      },
    },
    {
      path: '/active-sellers',
      name: 'active-sellers',
      component: () => import('@/views/active-suppliers/ActiveSellers.vue'),
      meta: {
        authority: 'Users_Partner_Browse_View',
      },
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const tauthority = to.matched[0].meta.authority

  if (!isLoggedIn && to.meta.resource !== 'Auth') {
    // Redirect to login if not logged in
    return next({ name: 'login' })
  }

  if (tauthority && !getUserRights().includes(tauthority)) {
    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser())
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router