import Vue from 'vue'

import axios from '@/libs/axios'

const getCacheKey = (endpoint, params) => {
  let hash = endpoint

  if (typeof params === 'object') {
    Object.keys(params).forEach(prop => {
      hash += `__${prop}_${params[prop]}`
    })
  }

  return hash
}

export default {
  namespaced: true,
  state: {
    cache: {
      items: {},
      validFor: 1000 * 60 * 10, // 10 minutes
    },
    userData: {
      id: null,
      languageCode: '',
      participant: {
        firstName: '',
        lastName: '',
        bio: '',
      },
      userGroup: {
        title: '',
      },
      activeUserGroup: {
        title: '',
      }
    },
    entity: {
      type: '',
      item: '',
    },
  },
  getters: {
    cached: (state, getters) => key => {
      if (getters.isValid(key)) {
        return state.cache.items[key].data
      }

      return undefined
    },

    userData: state => state.userData,

    maxLeads: state => state.userData.activeUserGroup ? state.userData.activeUserGroup.maxLeads : state.userData.userGroup.maxLeads,
    maxPartners: state => state.userData.activeUserGroup ? state.userData.activeUserGroup.maxPartners : state.userData.userGroup.maxPartners,
    maxProducts: state => state.userData.activeUserGroup ? state.userData.activeUserGroup.maxProducts : state.userData.userGroup.maxProducts,
    maxUsers: state => state.userData.activeUserGroup ? state.userData.activeUserGroup.maxUsers : state.userData.userGroup.maxUsers,

    isValid: state => key => {
      const item = state.cache.items[key]
      const timestamp = Date.now()

      if (item && state.cache.validFor > timestamp - item.timestamp) {
        return true
      }

      return false
    },

    entity: state => state.entity,
  },
  mutations: {
    entity: (state, payload) => {
      state.entity.type = payload.type
      state.entity.item = payload.item
    },

    cache: (state, payload) => {
      // Cache item can be used to stop an ajax request without storing the data on this object
      state.cache.items[payload.key] = {
        timestamp: Date.now(),
        data: payload.data ? payload.data : null,
      }
    },

    userData: (state, payload) => {
      Object.entries(payload).forEach(([i, val]) => {
        Vue.set(state.userData, i, val)
      })
    },
  },
  actions: {
    jwtLogin(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/jwt/login', payload)
          .then(async response => resolve(response))
          .catch(error => reject(axios.handleError(error)))
      })
    },
    fetchUserGroupRights(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/usergrouprights/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCardlinkParam(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/payments/cardlink-payment', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUserLanguage({ getters, commit }, data) {
      axios.put('/users/language', { ...data })
      commit('userData', {
        ...getters.userData,
        ...data,
      })

      localStorage.setItem('userData', JSON.stringify(getters.userData))
    },
    updateUserData({ getters, commit }, data) {
      return axios.put('/users/profile', { ...data }).then(res => {
        commit('userData', {
          ...getters.userData,
          ...data,
        })

        localStorage.setItem('userData', JSON.stringify(getters.userData))

        return res
      })
    },
    fetchGenericList({ getters, commit }, payload) {
      const queryParams = payload.params
      const endpoint = payload.url
      const key = getCacheKey(endpoint, queryParams)

      if (getters.isValid(key)) {
        return getters.cached(key)
      }

      return new Promise((resolve, reject) => {
        axios
          .get(endpoint, { params: queryParams })
          .then(response => {
            commit('cache', {
              key,
              data: response,
            })

            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchBanks({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'banks/list' })
    },
    fetchCategories({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'categories/list' })
    },
    fetchCategoryList({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: '/categories/list/all-categories' })
    },
    fetchSubCategoryList({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: '/categories/list/all-sub-categories' })
    },
    fetchAttributes({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'categories/list/attributes' })
    },
    fetchSubAttributes({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'categories/list/sub-attributes' })
    },
    fetchDecorStyles({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'decor-styles/list' })
    },
    fetchCompanies({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'companies/list' })
    },
    fetchCoverAreas({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'cover-areas/list' })
    },
    fetchMunicipalities({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'municipalities/list' })
    },
    fetchCountries({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'countries/list' })
    },
    fetchCustomers({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'customers/list' })
    },
    fetchDeliveryMethods({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'delivery-methods/list' })
    },
    fetchPaymentMethods({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'payment-methods/list' })
    },
    fetchLeadSources({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'lead-sources/list' })
    },
    fetchLeadCategories({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'lead-categories/list' })
    },
    fetchActivePartners({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'partner-requests/active-list',
      })
    },
    fetchOrderStatuses({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'order-statuses/list' })
    },
    fetchOrderProductStatuses({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'order-product-statuses/list',
      })
    },
    fetchPostCategories({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'post-categories/list' })
    },
    fetchTags({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'tags/list' })
    },
    fetchProducts({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'products/list' })
    },
    fetchRoles({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'roles/sellers-suppliers' })
    },
    fetchSellers({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'companies/sellers-list' })
    },
    fetchSuppliers({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'companies/suppliers-list' })
    },
    fetchAllSellers({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'companies/all-sellers-list',
      })
    },
    fetchAllSuppliers({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'companies/all-suppliers-list',
      })
    },
    fetchShippingClasses({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'product-shipping-classes' })
    },
    fetchBadges({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'badges/list' })
    },
    fetchUsers({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'users/list' })
    },
    fetchUserGroups({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'user-groups/list' })
    },
    fetchSubscriptions({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'user-groups/subscriptions' })
    },
    fetchPlainSubscriptions({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', {
        params: queryParams,
        url: 'user-groups/subscriptions-list',
      })
    },
    fetchMainParameters({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: 'main-parameters/search' })
    },
    fetchVat({ dispatch }) {
      return dispatch('fetchGenericList', { url: 'main-parameters/4' })
    },
    fetchSocialLinkTypes({ dispatch }) {
      return dispatch('fetchGenericList', { url: 'companies/social-links-list' })
    },
    fetchColorOptions({ dispatch }, queryParams) {
      return dispatch('fetchGenericList', { params: queryParams, url: '/color/all-colors' })
    }
  }
}
