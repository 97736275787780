import axios from '@axios'

export default {
  namespaced: true,
  state: {
    unread: 0,
    unreadReq: 0,
  },
  getters: {
    unreadNotifications: (state) => state.unread,
    unreadRequests: (state) => state.unreadReq,
  },
  mutations: {
    unreadNotifications: (state, payload) => {
      state.unread = payload
    },
    unreadRequests: (state, payload) => {
      state.unreadReq = payload
    },
  },
  actions: {
    fetchChatsAndContacts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/chats/partners')
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    //to get count of new partner requests created and accepted
    fetchNewRequests() {
      return new Promise((resolve, reject) => {
        axios
          .get('/partner-request-notification/total-count')
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    getAllChatData(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/chats/${userId}`)
          .then((response) => {
            const chats = response.data.content
            resolve({ chats })
          })
          .catch((error) => reject(error))
      })
    },
    getNewMessages(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/chats/recipient/${userId}`)
          .then((response) => {
            const chats = response.data.content
            resolve({ chats })
          })
          .catch((error) => reject(error))
      })
    },
    sendMessage(ctx, { contactId, message, file, type }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/chats', {
            recipientId: contactId,
            message,
            file,
            type,
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    uploadPdf(ctx, file) {
      return new Promise((resolve, reject) => {
        axios
          .post('/chats/file-upload', file, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
